<template>
  <div class="banner">
    <img :src="`${$root.globalUrl}/${content.file}`" alt="">
  </div>
</template>

<script>


export default {
  name: "banner",
  data: () => ({}),
  props: {
    content: Object
  }
}
</script>

<style scoped lang="scss">
.banner {
  img {
    width: 100%;
    height: auto;
    display: block;
  }
}
</style>