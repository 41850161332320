<script>

export default {
  name: "double-text",
  data: () => ({}),
  props: {
    content: Object
  }
}
</script>

<template>
  <div class="double-text">
    <p v-for="key in content.blocks" >
      <span v-html="key.title"></span>
      <span v-html="key.description"></span>
    </p>
  </div>
</template>

<style scoped lang="scss">

.double-text {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 1em;
  @media screen and (max-width: 600px){
    grid-template-columns: repeat(1, 1fr);

  }

  p {
    display: block;
    width: 100%;
    color: #424242;
    font-size: 14px;
    font-family: "Commissioner-Regular";
  }
}
</style>